import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import { VueEditor } from "vue2-editor";
import APP_CONST from '@/constants/AppConst';
import API_CONST from '@/constants/ApiConst';
import { ITaskDetailDto } from "@/services/program/plannerService";
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import { addTask, editTask, deleteTask, updateTaskStatus, addFile, copyTask, addComment, deleteCollaboratorInViewTask, addNewCollaboratorInViewTask } from "@/services/program/plannerService";
import APP_UTILITIES from '@/utilities/commonFunctions';
import plannerStore from '@/store/modules/plannerStore';
import programConfigure from '@/store/modules/programConfigure';
@Component({
    components: {
        'datepicker': DatepickerComponent,
        'notes-editor': VueEditor,
        'progress-button': ProgressButton
    },
    filters: {
        initialLetter: function (value: string) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase();
        }
    }
})
export default class OperateTask extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    sections = APP_CONST.SECTIONS;
    validatedFields = JSON.parse(JSON.stringify(APP_CONST.TASK_FORM));
    dateType = '';
    validationIndicator = { entireFieldsEntered: false, wrongEndDate: false };
    searchOwner: string = '';
    searchCollaborator: string = '';
    taskUserIndex: number = -1;
    collaboratorDrop: boolean = false;
    ownerDrop: boolean = false;
    status: string[] = ['Active', 'Done', 'Skip'];
    status_dropdown: { id: number, name: string, img: string }[] = [{ id: 1, name: 'Active', img: 'active-icon.svg' }, { id: 2, name: 'Done', img: 'done-status.svg' }, { id: 3, name: 'Skip', img: 'skip-icon.svg' }];
    confirmationBox: boolean = false;
    statusBox: boolean = false;
    statusBoxMob = false;
    closeInfo: boolean = false;
    selectedFile: any = '';
    largeFileError: boolean = false;
    maxFileError: boolean = false;
    unsupportedFileError: boolean = false;
    hoverText: string = '';
    hovering: boolean = false;
    hoverIndex: number = -1;
    userId: number = 0;
    disableAddFileLink: boolean = false;
    disableCopyBtn: boolean = false;
    copyTaskRevert: boolean = false;
    copiedTaskId: number = -1;
    ElementRightZero = false;
    isFileUploadLinkActive: boolean = false;
    whatCanIUploadTextId = APP_CONST.WHAT_CAN_UPLOAD_TEXT_ID;
    public customToolbar: any = APP_CONST.CUSTOM_TOOLBAR;
    public comment: string = "";
    public prevDate: string = "";
    public showSeeMoreButton: boolean = false;
    public showSeeLessButton: boolean = false;
    public apiResponseCode: number = 0;
    public isValid: boolean = true;
    public deviceFormatCheck: string = navigator.userAgent;
    public progressingComment: boolean = false;
    public disablePlaceholder = APP_CONST.DISABLE_PLACEHOLDER;
    public taskId : number = 0;
    startDateCopy:any = "";
    endDateCopy:any = "";
    public prevId : number = 0;

    @Prop()
    taskDetails!: ITaskDetailDto

    @Watch('taskDetails', { deep: true })
    checkHeight() {
        setTimeout(() => {
            this.calculateNotesHeight();
        }, 0);
        /* Google pixel 4 handling (@input not working in Google pixel 4)*/
        if (this.taskDetails && this.taskDetails.name) {
            this.checkNames('name', this.taskDetails.name);
        }
        if(this.mode == 'view'){
            this.startDateCopy = this.taskDetails.startDate;
            this.endDateCopy = this.taskDetails.endDate;
        }
    }

    @Prop()
    ownerList!: { id: number, profilePicture: [{ thumbnailUrl: string, imageUrl: string }], firstName: string, lastName: string, email: string }[]

    @Prop()
    mode!: string

    @Prop()
    isPdfPrintInProgress!: boolean

    @Prop()
    isMobileView!: boolean
    trim(x: string) {
        return (x && x.replace(/^\s+|\s+$/gm, '')) || '';
    }

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    filterOwner(item: { id: 0, taskId: 0, userId: 0, isOwner: boolean }[]) {
        return item.filter((el) => el.isOwner == true);
    }

    get siteId() {
        return plannerStore.getSiteId;
    }

    async downloadTask() {
        this.$emit("downloadTask");
    }

    base64ToArrayBuffer(data: any) {
        var bString = window.atob(data);
        var bLength = bString.length;
        var bytes = new Uint8Array(bLength);
        for (var i = 0; i < bLength; i++) {
            var ascii = bString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    mounted() {
        let userId: string | null = APP_UTILITIES.getCookie("user_id");
        this.userId = userId && parseInt(userId) || 0;
        this.toolTipConfig();
        const ele = document.getElementById('right-panel');
        if (ele) {
            ele.addEventListener("scroll", this.handleScroll);
        }
    }

    filterCollaborator(item: { id: 0, taskId: 0, userId: 0, isOwner: boolean }[]) {
        return item.filter((el) => el.isOwner == false);
    }

    onPaste(event: any) {
        /* will be executed only for notes editor */
        /* istanbul ignore else */
        if(this.sections[0].active){
            setTimeout(() => {
                const _ele : any = document.getElementById('right-panel');
                const _notesEditor : any = document.getElementById('notesEditor');
                _ele.scrollTop = (_notesEditor.clientHeight - _ele.clientHeight)
            }, 10);
        }
    }

    addUpTask(payload: ITaskDetailDto) {
        const { programId, siteId } = APP_UTILITIES.coreids();
        const ValidateObj = this.validatedFields;
        const checkValidation = APP_UTILITIES.requiredFields(payload, ValidateObj);
        this.validatedFields = { ...checkValidation.validateData };
        if (checkValidation.isFormInvalid) {
            this.validationIndicator.entireFieldsEntered = checkValidation.isFormInvalid;
            return;
        }
        if (this.validationIndicator.wrongEndDate) {
            return;
        }

        payload.startDate = APP_UTILITIES.getFullDate(payload.startDate);
        payload.endDate = APP_UTILITIES.getFullDate(payload.endDate);
        payload.siteId = siteId ? siteId : this.siteId;
        this.isValid = false;
        addTask(payload).then((response) => {
            if (response.status === APP_CONST.RESPONSE_200) {
                this.apiResponseCode = APP_CONST.RESPONSE_200;
                const getTasksParam = { programId: programId, productId: programConfigure.productId, siteId: siteId ? siteId : this.siteId };
                plannerStore.tasks(getTasksParam);
                setTimeout(() => {
                    this.$emit('updateSorting')
                }, 500);
            } else {
                this.apiResponseCode = 400;
            }
        }, () => {
            this.close();
        })
    }

    modifyTask(payload: ITaskDetailDto) {
        const { programId, siteId } = APP_UTILITIES.coreids();
        payload.programId = programId;
        payload.siteId = this.siteId;
        const ValidateObj = this.validatedFields;
        const checkValidation = APP_UTILITIES.requiredFields(payload, ValidateObj);
        this.validatedFields = { ...checkValidation.validateData };
        let taskDetails:any = this.taskDetails;
        /* istanbul ignore else */
        if(!this.isOwnerOrCollaborator()){
            payload.startDate = payload.startDate!=this.startDateCopy ? this.startDateCopy : payload.startDate;
            payload.endDate = payload.endDate!=this.endDateCopy ? this.endDateCopy : payload.endDate;
        }
        /* istanbul ignore else */
        if (checkValidation.isFormInvalid) {
            this.validationIndicator.entireFieldsEntered = checkValidation.isFormInvalid;
            return;
        }
        /* istanbul ignore else */
        if (this.validationIndicator.wrongEndDate) {
            return;
        }
        payload.startDate = APP_UTILITIES.getFullDate(payload.startDate);
        payload.endDate = APP_UTILITIES.getFullDate(payload.endDate);
        this.isValid = false;
        editTask(payload).then((response) => {
            if (response.status === APP_CONST.RESPONSE_200) {
                this.apiResponseCode = APP_CONST.RESPONSE_200;
                const getTasksParam = { programId: programId, productId: programConfigure.productId, siteId: siteId ? siteId : this.siteId };
                plannerStore.tasks(getTasksParam);
                setTimeout(() => {
                    this.$emit('updateSorting')
                }, 500);
            } else {
                this.apiResponseCode = 400;
            }
        })
    }

    removeTask(id: number) {
        
        deleteTask(id).then((response) => {
            if (response.status === APP_CONST.RESPONSE_200) {
                if (this.taskDetails.id === id) {
                    this.close();
                } else {
                    this.closeCopyTaskRevertPop();
                }
                this.disableCopyBtn = false;
                const { programId, siteId  } = APP_UTILITIES.coreids();
                const getTasksParam = { programId: programId, productId: programConfigure.productId, siteId: siteId };
                plannerStore.tasks(getTasksParam);
                setTimeout(() => {
                    this.$emit('updateSorting')
                }, 500);
            }
        })
    }

    checkNames(key: "name" | "startDate" | "endDate", model: string) {
        let taskData : any = this.taskDetails.taskNote;
        this.taskId = taskData.taskId;
        if (this.validatedFields.hasOwnProperty(key)) {
            this.validatedFields[key].error = model ? false : true;
        }
    }

    checkForCorrectDate(key: "name" | "startDate" | "endDate", model: string) {
        this.dateType = ''
        this.validationIndicator.wrongEndDate = false;
        let startDate = new Date(this.taskDetails.startDate).toUTCString();
        let endDate = new Date(this.taskDetails.endDate).toUTCString();
        this.checkNames(key, model);
        if (startDate && endDate && Date.parse(endDate) < Date.parse(startDate)) {
            setTimeout(() => { this.taskDetails.endDate = '' })
            this.validationIndicator.wrongEndDate = true;
        }
    }

    switchNavigation(index: number, id: string){
        this.handleScroll();
        let tab = this.$refs as any;
        tab && tab[id] && tab[id].focus();
    }

    handleScroll() {
        setTimeout(() => {
            const ele = document.getElementById('right-panel');
            if (ele) {
                const sectionsVisible = [];
                for (let i = 0; i < ele.children.length; ++i) {
                    let visiblePageHeight = this.getVisibleHeight(ele.children[i]);
                    sectionsVisible.push({ index: i, percentage: ((visiblePageHeight / ele.children[i].clientHeight) * 100) })
                }
                let percentage = 0;
                let id = -1;
                sectionsVisible.forEach(section => {
                    if (section.percentage >= percentage) {
                        id = section.index;
                        percentage = section.percentage;
                    }
                });
                this.sections.forEach((el, el_index) => {
                    el.active = (el_index === id) ? true : false;
                })
            }
        }, 500)
    }

    getVisibleHeight(element: any) {
        const container = document.getElementById("right-panel");
        let scrollTop = 0, scrollBot = 0, containerRect: any = { height: 0, width: 0, x: 0, y: 0 };
        if (container) {
            scrollTop = container.scrollTop;
            scrollBot = scrollTop + container.clientHeight;
            containerRect = container.getBoundingClientRect();
        }
        let eleRect = element.getBoundingClientRect();
        let rect = { top: 0, right: 0, bottom: 0, left: 0 };
        rect.top = eleRect.top - containerRect.top,
            rect.right = eleRect.right - containerRect.right,
            rect.bottom = eleRect.bottom - containerRect.bottom,
            rect.left = eleRect.left - containerRect.left;
        let eleTop = rect.top + scrollTop;
        let eleBot = eleTop + element.offsetHeight;
        let visibleTop = eleTop < scrollTop ? scrollTop : eleTop;
        let visibleBot = eleBot > scrollBot ? scrollBot : eleBot;

        return visibleBot - visibleTop;
    }

    getPic(type: string, base64: string) {
        let profileImageSrc = '';
        if (type !== '' || base64 !== '') {
            profileImageSrc = 'data:' + type + ';' + 'base64,' + base64;
        }
        return profileImageSrc;
    }

    filterOwners(ownerList: {firstName: string, lastName: string}[], search: string) {
        return ownerList.filter((el: {firstName: string, lastName: string})=> {
            return `${el.firstName +' '+ el.lastName}`.toLowerCase().includes(search.toLowerCase())
        })
    }

    assignOwner(owner: { id: number, firstName: string, lastName: string }, userId: number, isOwner: boolean) {
        if(this.mode =='view'){
           this.$emit('ownerInfo',{newOwnerId:owner.id, taskId:this.taskId});
        }
        const usersList = this.ownerList.length ? this.ownerList : [];
        const taskUsers = JSON.parse(JSON.stringify(this.taskDetails.taskUsers));
        const index = usersList.findIndex((userDt: { id: number, profilePicture: [{ thumbnailUrl: string, imageUrl: string }], firstName: string, lastName: string }) => userDt.id === owner.id);
        const taskUserIndex = taskUsers.findIndex((user: { id: number, taskId: number, userId: number, isOwner: boolean, profilePicture: [{ thumbnailUrl: string, imageUrl: string }], firstName: string, lastName: string, email: string }) => (user.userId === userId) && (user.isOwner === isOwner));
        if ((taskUserIndex >= 0) && (index >= 0)) {
            taskUsers[taskUserIndex].profilePicture = usersList[index].profilePicture;
            taskUsers[taskUserIndex].firstName = usersList[index].firstName;
            taskUsers[taskUserIndex].lastName = usersList[index].lastName;
            taskUsers[taskUserIndex].email = usersList[index].email;
            taskUsers[taskUserIndex].userId = owner.id;
            taskUsers[taskUserIndex].isOwner = isOwner;
            this.taskDetails.taskUsers = JSON.parse(JSON.stringify(taskUsers));
            this.taskUserIndex = -1;
            this.searchOwner = '';
        }
    }

    assignFirstOwner(owner: { id: number, firstName: string, lastName: string }) {
        if(this.mode =='view'){
            this.$emit('ownerInfo',{newOwnerId:owner.id, taskId:this.taskId});
         }
        const usersList = this.ownerList.length ? this.ownerList : [];
        const index = usersList.findIndex((userDt: { id: number, profilePicture: [{ thumbnailUrl: string, imageUrl: string }], firstName: string, lastName: string }) => userDt.id === owner.id);
        if (index >= 0) {
            this.taskDetails.taskUsers.push({
                profilePicture: usersList[index].profilePicture, firstName: usersList[index].firstName,
                lastName: usersList[index].lastName, email: usersList[index].email, userId: usersList[index].id, isOwner: true, id: 0, taskId: 0
            });
            this.ownerDrop = false;
        }
    }

    async addColaborator(collaborator: { id: number, firstName: string, lastName: string }) {
        let response: any = {};
        if(this.mode == 'view'){
            if(collaborator.id == this.prevId){
               return;
            }
            this.prevId = collaborator.id;
            let payload = { taskId: this.taskId, collaboratorId: collaborator.id};
            response = await addNewCollaboratorInViewTask(payload);
        }
        const taskIndex = this.taskDetails.taskUsers.findIndex(el => (el.userId === collaborator.id) && el.isOwner === false);
        if ((taskIndex < 0) && (collaborator.id > 0)) {
            const usersList = this.ownerList.length ? this.ownerList : [];
            const index = usersList.findIndex((userDt: { id: number, profilePicture: [{ thumbnailUrl: string, imageUrl: string }], firstName: string, lastName: string }) => userDt.id === collaborator.id);
            if (index >= 0) {
                const newTask = {
                    id: 0, taskId: 0, userId: collaborator.id, isOwner: false, profilePicture: usersList[index].profilePicture,
                    firstName: usersList[index].firstName, lastName: usersList[index].lastName, email: usersList[index].email
                }
                if(this.mode!=='view'){
                    this.taskDetails.taskUsers.push(newTask);
                }
                else{
                    newTask.id=response.data;
                    this.taskDetails.taskUsers.push(newTask);
                }
                this.collaboratorDrop = false;
            }
        } else {
            this.collaboratorDrop = false;
        }
        this.$nextTick(() => {
            this.setElementPosition();
        })
    }

    deleteColaborator(userId: number, id:number) {
        if(this.mode == 'view'){
           deleteCollaboratorInViewTask(id)
        }
        const collaboratorIndex = this.taskDetails.taskUsers.findIndex(el => (el.userId === userId) && el.isOwner === false);
        if (collaboratorIndex >= 0) {
            this.taskDetails.taskUsers.splice(collaboratorIndex, 1);
        }
        this.$nextTick(() => {
            this.setElementPosition();
        })
    }

    getColorCode(email: string) {
        return APP_UTILITIES.getColorCode(email);
    }

    monthddyyyy(date: string) {
        if (this.isTomorrow(date)) {
            return `Tomorrow (${APP_UTILITIES.formatDate(date, false, true)})`;
        } else {
            return date ? APP_UTILITIES.formatDate(date, false, true) : '- -';
        }
    }

    isTomorrow(date: string) {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const dateTocheck = new Date(date);
        return dateTocheck.getDate() === tomorrow.getDate() && dateTocheck.getMonth() === tomorrow.getMonth() && dateTocheck.getFullYear() === tomorrow.getFullYear();
    }

    changeMode(mode: string) {
        this.$emit('mode', { mode: mode });
        setTimeout(() => {
            this.toolTipConfig();
        }, 1000);
    }

    selectTaskStatus(status: number, id: number) {
        const payload = { status, id }
        updateTaskStatus(payload).then((response) => {
            if (response.status === APP_CONST.RESPONSE_200) {
                this.taskDetails.status = status;
                this.statusBox = false;
                this.statusBoxMob = false;
                const { programId, siteId } = APP_UTILITIES.coreids();
                const getTasksParam = { programId: programId, productId: programConfigure.productId, siteId: siteId };
                plannerStore.tasks(getTasksParam);
            }
        })
    }

    close() {
        this.$emit('close', { name: 'operate-task' });
        this.confirmationBox = false;
        this.searchOwner = '';
        this.searchCollaborator = '';
        this.statusBox = false;
        this.statusBoxMob = false;
        this.validationIndicator = { entireFieldsEntered: false, wrongEndDate: false };
        this.validatedFields = JSON.parse(JSON.stringify(APP_CONST.TASK_FORM));
        this.switchNavigation(0, 'right-panel');
        this.largeFileError = false;
        this.maxFileError = false;
        this.unsupportedFileError = false;
        this.copyTaskRevert = false;
        this.disableCopyBtn = false;
        this.copiedTaskId = -1;
        this.comment = "";
        this.prevDate = "";
        const conent: any = document.querySelector('#mobile-tabs');
        conent.scrollLeft -= 300;
        this.showSeeMoreButton = false;
        this.showSeeLessButton = false;
        this.apiResponseCode = 0;
        this.isValid = true;
        this.isFileUploadLinkActive = false;
        this.closeInfo = false;
        this.progressingComment = false;
    }

    updateDiscussionIcon() {
        const { programId, siteId } = APP_UTILITIES.coreids();
        const getTasksParam = { programId: programId, productId: programConfigure.productId, siteId: siteId ? siteId : this.siteId };
        plannerStore.tasks(getTasksParam);
    }

    checkFileType(ext: string) {
        let fileType = ["jpg", "pdf", "csv", "jpeg", "gif", "png", "doc", "txt", "docx", "xls", "xlsx", "ppt", "pptx", "jfif"];
        return fileType.some((filetype: any) => {
            return filetype.toLowerCase() == ext.toLowerCase()
        })
    }

    getFileNameAndtype(name: any) {
        const lastDot = name.lastIndexOf('.');
        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);
        return { fileName: fileName, ext: ext }
    }
    replaceWhiteSpaceFromFileName1(name: string) {
        if (name) {
            return name.replace(/\s/g, '')
        }
        return ''
    }
    checkDuplicateFile(file: File) {
        return this.taskDetails.taskFiles.some((fileobj: any) => {
            return this.replaceWhiteSpaceFromFileName1(file.name) == this.replaceWhiteSpaceFromFileName1(fileobj.fileName)
        })
    }
    fileHandler(event: any) {
        if (event.target.files.length <= 5) {
            this.unsupportedFileError = false;
            if((this.taskDetails.taskFiles.length+event.target.files.length)>5){
                this.unsupportedFileError = false;
                this.maxFileError = true;
            } else {
                this.maxFileError = false;
                for (var fileItem = 0; fileItem < event.target.files.length; fileItem++) {

                    if (event.target.files[fileItem].size > 5000000) {
                        this.unsupportedFileError = false;
                        this.largeFileError = true;
                    }
                    else if (!this.checkFileType(this.getFileNameAndtype(event.target.files[fileItem].name).ext)) {
                        this.largeFileError = false;
                        this.unsupportedFileError = true;
                    } else {
                        this.largeFileError = false;
                        this.disableAddFileLink = true;
    
                        if (this.checkDuplicateFile(event.target.files[fileItem])) {
                            let fileArray: Array<any> = JSON.parse(JSON.stringify(this.taskDetails.taskFiles))
                            fileArray.push({ file: event.target.files[fileItem], fileNameWithoutType: APP_UTILITIES.getFileNameAndtype(event.target.files[fileItem].name).fileName, fileName: event.target.files[fileItem].name })
                            var newFileName = APP_UTILITIES.renameFile(fileArray)[APP_UTILITIES.renameFile(fileArray).length - 1] + '.' + APP_UTILITIES.getFileNameAndtype(event.target.files[fileItem].name).ext
                            var fileToAdd= new File([event.target.files[fileItem]], newFileName, { type: event.target.files[fileItem].type });
                            
                            this.appendTaskFiles(fileToAdd);
                        }
                        else {
                            this.appendTaskFiles(event.target.files[fileItem])
                        }
                    }
                }
            }
        }
        else {
            this.unsupportedFileError = false;
            this.maxFileError = true;
        }

    }

    clearPreviousInput(inputElement: any) {
        inputElement.target.value = "";
    }

    appendTaskFiles(item: any) {
        if (this.taskDetails.taskFiles.length >= 5) {
            return;
        } else {
            var formData = new FormData();
            formData.append('formFile', item);
            var type = APP_CONST.TASK;
            addFile(type, formData).then((response) => {
                if (response.status === APP_CONST.RESPONSE_200) {
                    item.id = response.data;
                    item.fileUrl = process.env.VUE_APP_BASE_DOWNLOAD_FILE_URL + API_CONST.DOWNLOAD_TASK_FILE + response.data + '/' + item.name;
                    item.fileName = item.name;
                    this.taskDetails.taskFiles.push(item);
                    this.taskDetails.fileIds.push(response.data);
                    this.disableAddFileLink = false;
                } else {
                    this.disableAddFileLink = false;
                }
            });
            this.selectedFile = '';
        }
    }

    removeFileById(fileId: number, event: any) {
        var fileIdIndex = this.taskDetails.fileIds.findIndex(function (id) {
            return id === fileId;
        });
        if (fileIdIndex !== -1) {
            this.taskDetails.fileIds.splice(fileIdIndex, 1);
            var index = this.taskDetails.taskFiles.findIndex(function (obj) {
                return obj.id === fileId;
            });
            if (index !== -1) {
                this.taskDetails.taskFiles.splice(index, 1);
            };
        } else {
            var index = this.taskDetails.taskFiles.findIndex(function (obj) {
                return obj.id === fileId;
            });
            if (index !== -1) {
                this.taskDetails.deletedFileIds.push(this.taskDetails.taskFiles[index].id)
                this.taskDetails.taskFiles.splice(index, 1);
            };
        }
        event.stopPropagation();
    }

    checkForSelected(user: { id: number, profilePicture: [{ thumbnailUrl: string, imageUrl: string }], firstName: string, lastName: string, email: string }, check: boolean) {
        const userIndex = this.taskDetails.taskUsers.findIndex(el => (el.userId === user.id) && el.isOwner === check);
        if (userIndex >= 0) {
            return false;
        }
        else {
            return true;
        }
    }

    checkForOwner() {
        const userIndex = this.taskDetails.taskUsers.findIndex(el => el.isOwner === true);
        if (userIndex >= 0) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Profile tooltip
     * 
     * @param text 
     */
    showTooltip(text: string) {
        this.hoverText = text;
        this.hovering = true;
    }

    callHover(index: number, text: string, $event: any) {
        if ($event.target.offsetWidth < $event.target.scrollWidth) {
            this.hoverText = text;
            this.hovering = true;
            this.hoverIndex = index;
        }
    }

    callHoverOut() {
        this.hoverText = '';
        this.hovering = false;
        this.hoverIndex = -1;
    }

    filterDropdownStatus() {
        return this.status_dropdown.filter((el) => el.id !== this.taskDetails.status);
    }

    downLoadFile(obj: any) {
        if (obj.fileUrl && !this.isMobileView) {
            window.open(obj.fileUrl, '_blank')
        } else {
            if (!this.isMobileView) {
                let url = process.env.VUE_APP_BASE_DOWNLOAD_FILE_URL + API_CONST.DOWNLOAD_TASK_FILE + obj.id + '/' + obj.name;
                window.open(url, '_blank')
            }
        }
    }

    getPosition(el: any) {
        var xPos = 0;
        var yPos = 0;

        while (el) {
            if (el.tagName == "BODY") {
                // deal with browser quirks with body/window/document and page scroll
                var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
                var yScroll = el.scrollTop || document.documentElement.scrollTop;

                xPos += (el.offsetLeft - xScroll + el.clientLeft);
                yPos += (el.offsetTop - yScroll + el.clientTop);
            } else {
                // for all other non-BODY elements
                xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
                yPos += (el.offsetTop - el.scrollTop + el.clientTop);
            }

            el = el.offsetParent;
        }
        return {
            x: xPos,
            y: yPos
        };
    }

    setElementPosition() {
        const rightPanel = document.getElementById('right-panel');
        const panelWidth = (rightPanel && rightPanel.clientWidth) || 0;
        const windowWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        const screenWidth = windowWidth <= 768 ? windowWidth : panelWidth;
        const myElement = document.querySelector("#collaborator");
        const position = this.getPosition(myElement);
        const diff = screenWidth - (position.x + 184);
        if (windowWidth <= 768) {
            if (diff < 120) {
                this.ElementRightZero = true;
            } else {
                this.ElementRightZero = false;
            }
        } else {
            if (diff < -184) {
                this.ElementRightZero = true;
            } else {
                this.ElementRightZero = false;
            }
        }
    }

    isOwnerOrCollaborator() {
        const userIndex = this.taskDetails.taskUsers.findIndex(el => el.userId === this.userId);
        if (userIndex >= 0) {
            return true;
        } else {
            return false;
        }
    }

    copyCurrentTask(id: number) {
        this.disableCopyBtn = true;
        const { programId, siteId } = APP_UTILITIES.coreids();
        let payload = {
            taskId: id,
            siteId: siteId ? siteId : this.siteId,
            programId: programId
        }
        copyTask(payload).then((response) => {
            if (response.status === APP_CONST.RESPONSE_200) {
                this.copiedTaskId = response.data;
                this.copyTaskRevert = true;
                const getTasksParam = { programId: programId, productId: programConfigure.productId, siteId: payload.siteId };
                plannerStore.tasks(getTasksParam);
            }
        });
    }

    closeCopyTaskRevertPop() {
        this.copyTaskRevert = false;
        this.disableCopyBtn = false;
        this.copiedTaskId = -1;
    }

    submitComment() {
        this.progressingComment = true;
        let taskDetails:any = this.taskDetails;
        if (this.comment.trim() === "" || !this.isOwnerOrCollaborator()) {
            this.comment = "";
            this.progressingComment = false;
            return;
        }
        let localDate = new Date();
        let dateInUTC = new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(), localDate.getUTCHours(), localDate.getUTCMinutes(), localDate.getUTCSeconds());
        let comment = {
            id: 0,
            taskId: 0,
            comment: this.comment,
            userId: this.userId,
            createdOn: dateInUTC
        }
        if (this.taskDetails.id === 0) {
            this.taskDetails.taskDiscussions.push(comment);
            this.comment = "";
            this.progressingComment = false;
        } else {
            comment.taskId = this.taskDetails.id;
            addComment(comment).then((response) => {
                if (response.status === APP_CONST.RESPONSE_200) {
                    this.taskDetails.taskDiscussions.push(comment);
                    this.comment = "";
                    this.progressingComment = false;
                    if (this.taskDetails.taskDiscussions.length === 1) {
                        this.updateDiscussionIcon();
                    }
                }
            });
        }
    }

    convertUTCDateToLocalDate(date: any) {
        var newDate = new Date(date);
        newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return newDate;
    }

    formattedDate(date: string) {
        return date ? APP_UTILITIES.formatDate(date, true, true) : '';
    }

    compareDates(index: number) {
        if (this.formattedDate(this.taskDetails.taskDiscussions[index].createdOn.toString()) !== this.formattedDate(this.taskDetails.taskDiscussions[index - 1].createdOn.toString())) {
            return true;
        }
    }

    formattedTime(date: string) {
        let newDate = this.convertUTCDateToLocalDate(new Date(date));
        return newDate ? APP_UTILITIES.formatAMPM(newDate.toString()) : '';
    }

    getUserData(id: number, attr: string) {
        var userData: any = this.ownerList.filter((el) => el.id == id);
        if (userData.length) {
            if (attr === "profilePicture") {
                return userData[0][attr][0].thumbnailUrl;
            } else {
                return userData[0][attr];
            }
        }
    }
    checkNotesChange(delta: any, oldDelta: any, source: string) {
        let s = Object(this.$refs.notes);
        s.quill.deleteText(650, 1, source)
        if (this.mode != "view") {
            delta.ops.forEach((element: any) => {
                let result1 = element.hasOwnProperty('attributes');
                if (result1) {
                    let result2 = element["attributes"].hasOwnProperty('link');
                    if (result2) {
                        var linkName = element["attributes"].link;
                        if (((linkName.substring(0, 4)).toLowerCase() + linkName.substring(4, 7)) !== 'http://' && ((linkName.substring(0, 5)).toLowerCase() + linkName.substring(5, 8)) !== 'https://') {
                            linkName = 'http://' + linkName;
                            element["attributes"].link = linkName;
                            s.quill.updateContents(delta);
                        }
                    }
                }
            });
        }
    }
    toolTipConfig() {
        let tooltips: any = {
            'bold': 'Bold',
            'italic': 'Italic',
            'underline': 'Underline',
            'strike': 'Strikethrough',
            'bullet': 'Bulleted List',
            'ordered': 'Numbered List',
            '-1': 'Decrease Indent',
            '+1': 'Increase Indent',
            'link': 'Insert Link'
        };

        let s = Object(this.$refs.notes);
        var Pos = s.quill.getModule('toolbar').container.querySelectorAll('button')
        for (let el of Pos) {
            var tooltip = el.className.replace('ql-', '');
            var className = el.className;
            el.setAttribute('data-tooltip', el.value == "" ? tooltips[tooltip] : tooltips[el.value])
            el.setAttribute('class', '');
            el.setAttribute('class', className + ' tool')

        }
        var Pos1 = s.quill.getModule('toolbar').container.querySelectorAll('span');
        for (let el2 of Pos1) {
            if (el2.className.indexOf('ql-color') > -1) {
                var className1 = el2.className;
                el2.setAttribute('data-tooltip', 'Text Color')
                el2.setAttribute('class', '');
                el2.setAttribute('class', className1 + ' tool')
            }

        }

    }

    calculateNotesHeight() {
        const ele = document.getElementById('text-shown-task');
        const eleHeight = ele && ele.offsetHeight;
        const eleHeightScroll = ele && ele.scrollHeight;
        if (this.isMobileView && eleHeight) {
            if (((this.deviceFormatCheck.indexOf('iPhone') != -1) || (this.deviceFormatCheck.indexOf('iPad') != -1)) && (eleHeight > 176)) {
                this.showSeeMoreButton = true;
            } else if ((this.deviceFormatCheck.indexOf('Android') != -1) && eleHeightScroll && (eleHeightScroll >= 186)) {
                this.showSeeMoreButton = true;
            }
        } else if (eleHeight && (eleHeight >= 248)) {
            this.showSeeMoreButton = true;
        }
    }

    showMore() {
        this.showSeeMoreButton = false;
        this.showSeeLessButton = true;
    }

    showLess() {
        this.showSeeLessButton = false;
        this.showSeeMoreButton = true;
        const elRightPanel = document.getElementById('right-panel');
        elRightPanel && (elRightPanel.style.scrollBehavior = "smooth");
        elRightPanel && (elRightPanel.scrollTop = 0);
        elRightPanel && (elRightPanel.style.scrollBehavior = "auto");
    }
}